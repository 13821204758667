import {
  Breadcrumb, BreadcrumbItem, Card, Col, ComboBox, Container, Link,
  Row,
  Typography,
} from '@airbus/components-react';
import React from 'react';
import { Outlet } from 'react-router-dom';
import './UploadFliHistory.scss';
import {
  COMBOBOX_PLACEHOLDER,
  HISTORY_TABLE_DATA, HOME, OPERATOR, UPLOAD_HISTORY,
} from '../../utils/constants';
import Table from '../Shared/Table/Table';
import { LOCALES } from '../../assets/locale';
import AppHeader from '../Shared/AppHeader/AppHeader';
import { historyTableColumns } from './UploadFliHistoryTableStructure';
import { useAppSelector } from '../../store/hooksTypes';

const UploadFliHistory: React.FC = function UploadFliHistory() {
  const checkerState = useAppSelector((state) => state.checkerFleet);
  return (
    <Container className="bg-coolgrey-10 p-2 history-table-container">
      <div>
        <AppHeader appName={LOCALES} />
        <Outlet />
      </div>
      <Card className="history-card-container">
        <Breadcrumb className="breadcrumb-container-history">
          <BreadcrumbItem>
            <Link href="/" className="home-page">
              {HOME}
            </Link>
          </BreadcrumbItem>
          <BreadcrumbItem current>{UPLOAD_HISTORY}</BreadcrumbItem>
        </Breadcrumb>
        <Row>
          <Col xxs={8}>
            <Typography variant="large" className="text-content">{UPLOAD_HISTORY}</Typography>
          </Col>
          <Col xxs={1}>
            <Typography variant="small" className="operator-text">{OPERATOR}</Typography>
          </Col>
          <Col xxs={2} className="combo-operator-col">
            <ComboBox
              aria-label="Select Operator"
              placeholder={COMBOBOX_PLACEHOLDER}
              value={checkerState.fleet_view_filter_selected?.selected_operator?.toString()}
              data-testid="upload-history-operator-selector"
              options={checkerState.fleet_view_filters?.operators_list}
              getOptionLabel={(option) => option}
              getOptionValue={(option) => option}
              hideClear
            />
          </Col>
        </Row>
      </Card>
      <Container className="history-page-container checker-details-container" id="historyContainer">
        <Table
          data-testid="history-data-table"
          tableData={HISTORY_TABLE_DATA}
          headerColumns={historyTableColumns}
          showChangeViewButton={false}
          showExportButton={false}
          showFilterButton={false}
          tableClassName="history-data-table"
          loadingupdate={false}
          showPagination={false}
        />
      </Container>
    </Container>
  );
};
export default UploadFliHistory;
